import React, { useState, useContext, useEffect, useCallback } from "react"
import { RootContext } from "../../RootContext"

// @material-ui/core components
import CssBaseline from "@material-ui/core/CssBaseline"
import Paper from "@material-ui/core/Paper"
import CardMedia from "@material-ui/core/CardMedia"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Input from "@material-ui/core/Input"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { useTranslation } from "react-i18next"
import { fetchWithToken } from "components/Fetch/api-fetch"

// pegaso components
import ErrorUtils from "components/Tools/ErrorUtils"
import { CircularIndeterminate } from "views/Loading"
import LoginFooter from "components/Footer/LoginFooter"
import LoginMaintenance from "components/Login/LoginMaintenance"

// css
import { useLoginStyles } from "styles/loginCss"

const EditField = ({ field, onChange, type = "text", value }) => (
  <FormControl margin="normal" required fullWidth>
    <InputLabel htmlFor={field}>{field}</InputLabel>
    <Input
      id={field}
      name={field}
      autoComplete={field}
      autoFocus
      onChange={onChange}
      type={type}
      value={value}
    />
  </FormControl>
)

export default props => {
  const classes = useLoginStyles()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const { t } = useTranslation()
  const {
    login,
    apiUrl,
    getRetailerId,
    isRetailerUser,
    setAllFilterCompanies,
    setAccess_token,
    setAllRetailers,
  } = useContext(RootContext)
  const [fetching, setFetching] = useState(false)
  const [loginError, setloginError] = useState()
  const [fetchingCheckManager, setFetchingCheckManager] = useState(true)
  const [checkManager, setCheckManager] = useState()


  const handleChange = e => {
    if (e.target.id === "username") {
      setUsername(e.target.value)
    } else if (e.target.id === "password") {
      setPassword(e.target.value)
    }
  }

  function viewLink() {
    let result = `/resources/images/administrator.png`
    return result
  }


  //Fetch -  Elenco retailer
  const fetchRetailers = useCallback((access_token, refresh_token, setAllRetailers) => {
    const url = `${apiUrl}/retailers`
    fetchWithToken(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then((retailer) => {
        setAllRetailers(retailer)
      })
      .catch((err) => {
        ErrorUtils.errorLog("fetchRetailers  Error", err)
      })
  }, [apiUrl, setAccess_token])

  function TryLogin(fetchString, isAdminFetch, defaultSelected) {
    const user = {
      username,
      password
    }

    setFetching(true)
    fetch(`${apiUrl}/${fetchString}`, {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(user)
    })
      .then(res =>
        res
          .json()
          .then(json => {
            const { access_token, refresh_token } = json
            if (access_token !== undefined || refresh_token !== undefined) {
              login(access_token, refresh_token)

              // Fetch FilterCompanies
              var retailerId = getRetailerId()
              var reduced = false;
              var scopeId = -1;
              const url = `${apiUrl}/companies?reduced=${reduced}&scopeId=${scopeId}&retailer=${retailerId}`
              fetchWithToken(url, {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                apiUrl: apiUrl,
                access_token: access_token,
                refresh_token: refresh_token,
                setAccess_token: setAccess_token,
              })
                .then((response) => {
                  return response.body
                })
                .then(({ companies }) => {
                  companies.sort(function (a, b) {
                    if (parseInt(a.companyId) >= parseInt(b.companyId)) {
                      return 1
                    } else {
                      return -1
                    }
                  })
                  if (isRetailerUser()) {
                    companies = companies.filter(elem => {
                      return elem.license.retailer.retailerId === retailerId
                    })
                  }
                  fetchRetailers(access_token, refresh_token, setAllRetailers)

                  setAllFilterCompanies(companies)

                  // Redirect
                  const goto = `/admin/${defaultSelected}`
                  props.history.push(goto)
                })
                .catch((err) => {
                  throw Error("FetchAllFilterCompanies Error -> " + err)
                })


            } else {
              throw Error("Invalid Credential")
            }
          })
          .catch(err => {
            if (!isAdminFetch) {
              TryLogin("retailerlogin", true, "licenses")
            } else {
              setFetching(false)
              setloginError({ message: err.message })
              ErrorUtils.errorLog("Login", err.message)
            }
          })
      )
      .catch(err => {
        setFetching(false)
        const message = "Authentication Server Error"
        setloginError({ message: message })
        ErrorUtils.errorLog("Login", message)
      })
  }

  const handleClickLogin = () => {
    TryLogin("adminlogin", false, "opticianUsers")
  }

  const fetchCheckManager = useCallback(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/v1/info/check-manager`, {
      method: "GET",
      headers: {
        "Accept": "application/json"
      }
    })
      .then(response => {
        response.status === 200 ? setCheckManager(true) : setCheckManager(false)
        setFetchingCheckManager(false)
      })
      .catch((err) => {
        setCheckManager(false)
        setFetchingCheckManager(false)
      })
  },
    []
  )

  // useEffect 3 - Controllo che mini-manager è on-line
  useEffect(() => {
    if (fetchingCheckManager) {
      fetchCheckManager()
    }
  }, [fetchCheckManager, fetchingCheckManager])


  return fetching || fetchingCheckManager ? (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "scale(4, 4)"
      }}>
      <CircularIndeterminate className={classes.progress} />
    </div>
  ) : (
    checkManager ? (
      <div
        onKeyPress={e => {
          if (e.key === "Enter") {
            return handleClickLogin()
          }
        }}>
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <CardMedia component="img" image={viewLink()} />
            <Typography align="right" color="error">
              {loginError ? t(loginError.message) : ""}
            </Typography>
            <EditField field={t("10062")} onChange={handleChange} />
            <EditField
              field={t("10063")}
              onChange={handleChange}
              type="password"
            />
            {/*<div>
              <RadioButton
                lang="it"
                onChange={ev => changeLanguage("it", ev)}
                checked={i18n.language === "it"}
              />
              <RadioButton
                lang="GB"
                onChange={ev => changeLanguage("en", ev)}
                checked={i18n.language === "en"}
              />
            </div>*/}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleClickLogin}>
              {t("10064")}
            </Button>
          </Paper>
          <LoginFooter />
        </main>
      </div>
    ) : (
      <LoginMaintenance />
    )
  )
}
