import React, { useState } from "react"

// @material-ui/core components
import Cookies from "universal-cookie"
import decode from "jwt-decode"
import { useTranslation } from "react-i18next"
import { SnackbarProvider } from "notistack"

// pegaso components
import JobUtils from "components/Utils/JobUtils"

export const RootContext = React.createContext()
export const apiUrl = process.env.REACT_APP_API_URL + "/api/v1"
export const publicUrl = process.env.REACT_APP_PUBLIC_URL
export const brokerUrl = process.env.REACT_APP_BROKER_URL
export const brokerPort = process.env.REACT_APP_BROKER_PORT
export const brokerPrefix = process.env.REACT_APP_BROKER_PREFIX.trim()

export default ({ children }) => {
  // Get vars from sessionStorage and cookies
  const cookies = new Cookies()
  const prevAuth = window.sessionStorage.getItem("auth") || false
  const prevAccess_token = window.sessionStorage.getItem("access_token") || null
  const prevRefresh_token = cookies.get("refresh_token") || null
  const company_id = window.sessionStorage.getItem("company_id") || null
  const company_name = window.sessionStorage.getItem("company_name") || "Administrator Connect"
  const lens = JSON.parse(window.sessionStorage.getItem("lens")) || JobUtils.lensTypeValues()
  const all_filter_companies = JSON.parse(window.sessionStorage.getItem("all_filter_companies")) || null
  const all_retailers = JSON.parse(window.sessionStorage.getItem("all_retailers")) || null

  // States
  const [authenticated, setAuthenticated] = useState(prevAuth)
  const [companyId, setCompanyId] = useState(company_id)
  const [companyName, setCompanyName] = useState(company_name)
  const [allFilterCompanies, setAllFilterCompanies] = useState(all_filter_companies)
  const [allRetailers, setAllRetailers] = useState(all_retailers)
  const { t, i18n } = useTranslation() // per lingua
  const [access_token, setAccess_token] = useState(prevAccess_token)
  const [refresh_token, setRefresh_token] = useState(prevRefresh_token)

  document.title = companyName

  const [lensTypes, setLensTypes] = useState(lens)

  let user_claims_global

  const initializeUserClaimsForceToken = (token) => {
    if (!user_claims_global) {
      const { user_claims } = decode(token)
      user_claims_global = user_claims
    }
  }

  const initializeUserClaims = () => {
    if (!user_claims_global) {
      const { user_claims } = decode(access_token)
      user_claims_global = user_claims
    }
  }

  let identityGlobal
  const initializeIdentity = () => {
    if (!identityGlobal) {
      const { identity } = decode(access_token)
      identityGlobal = identity
    }
  }

  const defaultContext = {
    authenticated,
    access_token,
    refresh_token,
    t,
    companyId,
    setCompanyId,
    getLensTypes: () => {
      const result = lensTypes.map(lens => {
        return { value: lens["id"], label: t(lens["description"]) }
      })

      return result
    },
    setLensTypes: lensTypes => {
      sessionStorage.setItem("lens", JSON.stringify(lensTypes))
      setLensTypes(lensTypes)
    },
    companyName,
    setCompanyName: companyName => {
      sessionStorage.setItem("company_name", companyName)
      setCompanyName(companyName)
    },
    allFilterCompanies,
    setAllFilterCompanies: allFilterCompanies => {
      sessionStorage.setItem("all_filter_companies", JSON.stringify(allFilterCompanies))
      setAllFilterCompanies(allFilterCompanies)
    },
    allRetailers,
    setAllRetailers: allRetailers => {
      sessionStorage.setItem("all_retailers", JSON.stringify(allRetailers))
      setAllRetailers(allRetailers)
    },
    setAccess_token,
    login: (access_token, refresh_token) => {
      sessionStorage.setItem("company_id", companyId)
      sessionStorage.setItem("access_token", access_token, { path: "/" })
      cookies.set("refresh_token", refresh_token)
      setAccess_token(access_token)
      setRefresh_token(refresh_token)
      setAuthenticated(true)

      // Imposta lingua utente
      const { user_claims } = decode(access_token)
      const { language } = user_claims
      i18n.changeLanguage(language)
      initializeUserClaimsForceToken(access_token)
    },
    logout: () => {
      setAccess_token(null)
      setRefresh_token(null)
      setAuthenticated(false)
      setLensTypes(JobUtils.lensTypeValues())
      sessionStorage.removeItem("company_id")
      sessionStorage.removeItem("company_name")
      sessionStorage.removeItem("all_filter_companies")
      sessionStorage.removeItem("lens")
      sessionStorage.removeItem("access_token")
      sessionStorage.removeItem("catalogue")
      sessionStorage.removeItem("lens")
      sessionStorage.removeItem("requiredFields")
      cookies.remove("refresh_token")
    },
    getLab: () => {
      initializeUserClaims()
      const { lab } = user_claims_global
      return lab
    },
    isLabUser: () => {
      initializeUserClaims()
      const { roles } = user_claims_global
      return roles[0] === 2
    },
    isOpticianUser: () => {
      initializeUserClaims()
      const { roles } = user_claims_global
      return roles[0] === 3
    },
    isRetailerUser: () => {
      initializeUserClaims()
      const { roles } = user_claims_global
      return roles[0] === 5
    },
    getOptician: () => {
      initializeUserClaims()
      const { optician } = user_claims_global
      return optician
    },
    getUsername: () => {
      initializeIdentity()
      return identityGlobal
    },
    getUtc: () => {
      initializeUserClaims()
      var date = new Date();
      var localUTC = (date.getTimezoneOffset() / 60) * -1;
      user_claims_global.utc = localUTC
      const { utc } = user_claims_global
      return utc
    },
    getLanguage: () => {
      initializeUserClaims()
      const { language } = user_claims_global
      return language
    },
    getIsDemo: () => {
      initializeUserClaims()
      const { demo } = user_claims_global
      return demo
    },
    getIsDemoToken: access_token => {
      const { user_claims } = decode(access_token)
      const { demo } = user_claims
      return demo
    },
    getOpticianUserDevice: () => {
      initializeUserClaims()
      const { userDevice } = user_claims_global
      return userDevice
    },
    getRetailerId: () => {
      initializeUserClaims()
      const { retailer } = user_claims_global
      if (retailer) {
        const { retailerId } = retailer
        return retailerId
      } else {
        return undefined
      }
    },
    getRetailer: () => {
      initializeUserClaims()
      const { retailer } = user_claims_global
      return retailer
    },
    publicUrl,
    apiUrl,
    brokerUrl,
    brokerPort,
    brokerPrefix,
  }

  return (
    <SnackbarProvider maxSnack={3}>
      <RootContext.Provider value={defaultContext}>
        {children}
      </RootContext.Provider>
    </SnackbarProvider>
  )
}
