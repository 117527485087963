// @material-ui/icons
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import BusinessIcon from '@material-ui/icons/Business';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import DevicesIcon from '@material-ui/icons/Devices';
import Person from "@material-ui/icons/Person";
import RestoreOutlinedIcon from '@material-ui/icons/RestoreOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUp from "@material-ui/icons/TrendingUp";
import WorkIcon from '@material-ui/icons/Work';

// pegaso components/vies
import AdministratorUsers from "views/AdministratorUsers/AdministratorUsers"
import Companies from "views/Companies/Companies"
import Hubs from "views/Hubs/Hubs";
import Laboratories from "views/Laboratories/Laboratories"
import LaboratoryUsers from "views/LaboratoryUsers/LaboratoryUsers"
import Licenses from "views/Licenses/Licenses"
import LicensesGenerator from "views/LicensesGenerator/LicensesGenerator"
import Models from "views/Models/Models"
import OpticianUsers from "views/OpticianUsers/OpticianUsers"
import OpticianStatistics from "views/Statistics/OpticianStatistics"
import ResetCounter from 'views/ResetCounter/ResetCounter';
import RetailerUsers from "views/RetailerUsers/RetailerUsers"


const dashboardRoutesFull = [
  {
    path: "/companies",
    name: "companies",
    rtlName: "A",
    icon: BusinessIcon,
    component: Companies,
    description: "10041",
    layout: "/admin"
  },
  {
    path: "/laboratories",
    name: "laboratories",
    rtlName: "B",
    icon: WorkIcon,
    component: Laboratories,
    description: "10214",
    layout: "/admin"
  },
  {
    path: "/hubs",
    name: "hubs",
    rtlName: "C",
    icon: DeviceHubIcon,
    component: Hubs,
    description: "10215",
    layout: "/admin"
  },
  {
    path: "/laboratoryUsers",
    name: "laboratoryUsers",
    rtlName: "D",
    icon: Person,
    component: LaboratoryUsers,
    description: "10216",
    layout: "/admin"
  },
  {
    path: "/opticianUsers",
    name: "opticianUsers",
    rtlName: "E",
    icon: Person,
    component: OpticianUsers,
    description: "10217",
    layout: "/admin"
  },
  {
    path: "/retailerUsers",
    name: "retailerUsers",
    rtlName: "F",
    icon: Person,
    component: RetailerUsers,
    description: "10234",
    layout: "/admin"
  },
  {
    path: "/administratorUsers",
    name: "administratorUsers",
    rtlName: "G",
    icon: Person,
    component: AdministratorUsers,
    description: "10233",
    layout: "/admin"
  },
  {
    path: "/statistics",
    name: "statistics",
    rtlName: "O",
    icon: TrendingUp,
    component: OpticianStatistics,
    description: "10276",
    layout: "/admin"
  },
  {
    path: "/models",
    name: "models",
    rtlName: "H",
    icon: DevicesIcon,
    component: Models,
    description: "10218",
    layout: "/admin"
  },
  {
    path: "/licenses",
    name: "licenses",
    rtlName: "L",
    icon: ShoppingCartIcon,
    component: Licenses,
    description: "10223",
    layout: "/admin"
  },
  {
    path: "/licGenerator",
    name: "licGenerator",
    rtlName: "M",
    icon: AddShoppingCartIcon,
    component: LicensesGenerator,
    description: "10245",
    layout: "/admin"
  },
  {
    path: "/resetCounter",
    name: "resetCounter",
    rtlName: "N",
    icon: RestoreOutlinedIcon,
    component: ResetCounter,
    description: "10248",
    layout: "/admin"
  },
];

const dashboardRoutesPartial = [
  {
    path: "/Companies",
    name: "Companies",
    rtlName: "A",
    icon: BusinessIcon,
    component: Companies,
    description: "10041",
    layout: "/admin"
  },
  {
    path: "/hubs",
    name: "hubs",
    rtlName: "C",
    icon: DeviceHubIcon,
    component: Hubs,
    description: "10215",
    layout: "/admin"
  },
  {
    path: "/licenses",
    name: "licenses",
    rtlName: "L",
    icon: ShoppingCartIcon,
    component: Licenses,
    description: "10223",
    layout: "/admin"
  },
]

const dashboardRoutesPartial_2 = [
  {
    path: "/Companies",
    name: "Companies",
    rtlName: "A",
    icon: BusinessIcon,
    component: Companies,
    description: "10041",
    layout: "/admin"
  },
  {
    path: "/laboratories",
    name: "laboratories",
    rtlName: "B",
    icon: WorkIcon,
    component: Laboratories,
    description: "10214",
    layout: "/admin"
  },
  {
    path: "/laboratoryUsers",
    name: "laboratoryUsers",
    rtlName: "D",
    icon: Person,
    component: LaboratoryUsers,
    description: "10216",
    layout: "/admin"
  },
  {
    path: "/licenses",
    name: "licenses",
    rtlName: "L",
    icon: ShoppingCartIcon,
    component: Licenses,
    description: "10223",
    layout: "/admin"
  },
]

export { dashboardRoutesFull, dashboardRoutesPartial, dashboardRoutesPartial_2 };
