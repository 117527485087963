import React, { useEffect, useState, useContext } from "react"
import { RootContext, } from "RootContext"
import jsPDF from "jspdf";
import "jspdf-autotable";

//pegaso views/components
import AdminTable from "components/AdminTable/AdminTable"
import AdminTableUtils from "components/AdminTable/AdminTableUtils"
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder'
import DatesUtils from "components/Tools/DatesUtils"
import Tooltip from "@material-ui/core/Tooltip"
import LicenseAdvanced from "views/License/LicenseAdvanced"
import IconButton from "@material-ui/core/IconButton"
import UserUtils from "components/Utils/UserUtils"
import { LicensesCount, LicensesCountTable } from "components/Licenses/LicensesCount"
import { stableSort, getComparator } from "components/AdminTable/AdminTableSort"

// core components
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"

// Nomi dei campi delle colonne
function createData(id, licenseKey, retailer, creationDate, activationDate, active, company) {
  return {
    id,
    licenseKey,
    retailer,
    creationDate,
    activationDate,
    active,
    company
  }
}

export default function Licenses() {
  const {
    apiUrl,
    getUtc,
    isRetailerUser,
    getRetailerId,
    t,
    allRetailers
  } = useContext(RootContext)

  const [isRetailer] = useState(isRetailerUser())
  let [retailerId] = useState(getRetailerId())

  // Imposto retailer di default (se non è retailerUser metto 2-Visionix)
  let defaultRetailerId = 2
  if (isRetailer) {
    if (retailerId) {
      defaultRetailerId = retailerId
    }
  }

  const selectAdvancedLicense = [
    {
      value: "",
      label: "",
    },
    {
      value: "base",
      label: t("10262"),
    },
    {
      value: "advanced",
      label: t("10263"),
    },
  ]

  const [filter, setFilter] = useState([
    {
      id: "id",
      value: "",
      label: "id",
    },
    {
      id: "retailerId",
      value: defaultRetailerId,
      defaultValue: "",
      isSelect: true,
      selectOption: [],
      label: t("10226"),
      disabled: isRetailer,
    },
    {
      id: "company",
      value: "",
      label: t("10145")
    },
    {
      id: "licenseKey",
      value: "",
      label: t("10225")
    },
    {
      id: "creationDate",
      value: "",
      defaultValue: "",
      isDate: true,
      label: t("10200")
    },
    {
      id: "activationDate",
      value: "",
      defaultValue: "",
      isDate: true,
      label: t("10227")
    },
    {
      id: "advancedLicense",
      value: "",
      defaultValue: "",
      isSelect: true,
      selectOption: selectAdvancedLicense,
      label: t("10260")
    }
  ])

  const headCells = [
    { id: "id", numeric: true, disablePadding: false, label: t("10224").toUpperCase(), },
    { id: "licenseKey", numeric: false, disablePadding: false, label: t("10225").toUpperCase(), },
    { id: "retailerIdSort", numeric: false, disablePadding: false, label: t("10226").toUpperCase(), },
    { id: "creationDate", numeric: false, disablePadding: false, label: t("10200").toUpperCase() },
    { id: "activationDate", numeric: false, disablePadding: false, label: t("10227").toUpperCase() },
    { id: "active", numeric: false, disablePadding: false, label: t("10047").toUpperCase() },
    { id: "company", numeric: false, disablePadding: false, label: t("10117").toUpperCase() },
    { id: "city", numeric: false, disablePadding: false, label: t("10257").toUpperCase() },
    { id: "advancedLicense", numeric: false, disablePadding: false, label: t("10261").toUpperCase() },
  ]

  const [numLicenses, setNumLicenses] = useState({
    total: 0,
    used: 0,
    available: 0,
    free: 0,
  })

  const [allLicenses, setAllLicenses] = useState({
    total: 0,
    used: 0,
    available: 0,
    free: 0,
  })

  const orderByDefault = "id"
  const [urlAccessCode] = useState()
  const urlGet = `${apiUrl}/list-licenses?retailer=${retailerId}`

  /*************************************
   *
   * Cells
   *
   *************************************/

  // Quale campo è la chiave
  function getAdminTableKey(row) {
    return row.id
  }

  // Numero di colonne della tabella
  function getAdminTableCellsCount() {
    return 9
  }
  // Contenuto Celle
  function AdminTableCells(props) {
    const {
      keyToUse,
      labelId,
      row,
      rows,
      setRows,
    } = props
    const key = keyToUse
    row.creationDate = row.creationDate !== undefined ? row.creationDate : ""
    row.activationDate = row.activationDate !== undefined ? row.activationDate : ""
    row.company = row.company !== undefined ? row.company : ""
    row.city = row.city !== " - " ? row.city : ""
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow-" + key} >
        <TableCell
          key={"tableBodyCell0-" + key}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          {key ? key : ""}
        </TableCell>
        <TableCell key={"tableBodyCell1-" + key} align="left">
          {row.licenseKey ? row.licenseKey : ""}
        </TableCell>
        <TableCell key={"tableBodyCell4-" + key} align="left">
          {UserUtils.formatRetailer(row)}
        </TableCell>
        <TableCell key={"tableBodyCell5-" + key} align="left">
          {row.creationDate ? DatesUtils.formatDateWithUtc(row.creationDate, getUtc()) : ""}
        </TableCell>
        <TableCell key={"tableBodyCell6-" + key} align="left">
          {row.activationDate ? DatesUtils.formatDateWithUtc(row.activationDate, getUtc()) : ""}
        </TableCell>
        <TableCell key={"tableBodyCell7-" + key} align="left">
          {row.active !== undefined ? (row.active ? "True" : "False") : ""}
        </TableCell>
        <TableCell key={"tableBodyCell8-" + key} align="left">
          {row.company ? row.company : ""}
        </TableCell>
        <TableCell key={"tableBodyCell9-" + key} align="left">
          {row.company ? row.city : ""}
        </TableCell>
        <TableCell key={"tableBodyCell10-" + key} align="left">
          {row.licenseAdvanceds && row.licenseAdvanceds.length > 0 ? row.licenseAdvanceds.length : ""}
        </TableCell>
        {<AddAdvancedLicense keyToUse={keyToUse} adminTableRowData={row} rows={rows} setRows={setRows} />}
      </TableRow>
    )
  }

  //Bottone per visualizzazione e modifica moduli
  function AdminTableAdvancedLicenseButton(props) {
    const { handleClickOpen, } = props
    const { t } = useContext(RootContext)

    return (
      <div id="contenitore">
        <Tooltip title={t("10267")}>
          <IconButton
            aria-label={t("10266")}
            variant="outlined"
            onClick={() => {
              handleClickOpen()
            }}
          >
            <CreateNewFolderIcon />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  // Filtrare contenuto Celle
  function FilterAdminTableCells(rows) {
    const result = rows.length > 0
      ? rows.filter((row) => {
        return AdminTableUtils.filterField(
          row,
          row.id,
          filter.filter((filter) => {
            return filter.id === "id"
          })[0].value
        )
      })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            UserUtils.formatRetailer(row),
            filter.filter((filter) => {
              return filter.id === "retailerId"
            })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.company,
            filter.filter((filter) => {
              return filter.id === "company"
            })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.licenseKey,
            filter.filter((filter) => {
              return filter.id === "licenseKey"
            })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.creationDate,
            filter.filter((filter) => {
              return filter.id === "creationDate"
            })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.activationDate,
            filter.filter((filter) => {
              return filter.id === "activationDate"
            })[0].value
          )
        })
        .filter((row) => {
          return filterFieldLicense(
            row,
            row.licenseAdvanceds.length,
            filter.filter((filter) => {
              return filter.id === "advancedLicense"
            })[0].value
          )
        })

      : rows

    if (result.length > 0) {
      if (result) {
        let resultLicenses = LicensesCount(result)
        if (resultLicenses.total > 0) {
          if (resultLicenses.total !== numLicenses.total)
            setNumLicenses({ ...numLicenses, total: resultLicenses.total })
          if (resultLicenses.available !== numLicenses.available)
            setNumLicenses({ ...numLicenses, available: resultLicenses.available })
          if (resultLicenses.used !== numLicenses.used)
            setNumLicenses({ ...numLicenses, used: resultLicenses.used })
          if (resultLicenses.free !== numLicenses.free)
            setNumLicenses({ ...numLicenses, free: resultLicenses.free })
        }
      }
    } else {
      let resultLicenses = { total: 0, available: 0, used: 0, free: 0 }
      if (resultLicenses.total !== numLicenses.total) {
        setNumLicenses(resultLicenses)
      }
    }

    // 2023/09/08 - Testata TUTTE licenze
    const resultAll = rows.length > 0
      ? rows.filter((row) => {
        return AdminTableUtils.filterField(
          row,
          UserUtils.formatRetailer(row),
          filter.filter((filter) => {
            return filter.id === "retailerId"
          })[0].value
        )
      })
      : rows

    if (resultAll.length > 0) {
      if (resultAll) {
        let resultLicensesAll = LicensesCount(resultAll)
        if (resultLicensesAll.total > 0) {
          if (resultLicensesAll.total !== allLicenses.total)
            setAllLicenses({ ...allLicenses, total: resultLicensesAll.total })
          if (resultLicensesAll.available !== allLicenses.available)
            setAllLicenses({ ...allLicenses, available: resultLicensesAll.available })
          if (resultLicensesAll.used !== allLicenses.used)
            setAllLicenses({ ...allLicenses, used: resultLicensesAll.used })
          if (resultLicensesAll.free !== allLicenses.free)
            setAllLicenses({ ...allLicenses, free: resultLicensesAll.free })
        }
      }
    } else {
      let resultLicensesAll = { total: 0, available: 0, used: 0, free: 0 }
      if (resultLicensesAll.total !== allLicenses.total) {
        setAllLicenses(resultLicensesAll)
      }
    }

    return result
  }

  //Filtro per select customizzata 
  function filterFieldLicense(row, rowField, filterField) {
    if (filterField !== null && filterField !== undefined) {
      filterField = String.prototype.trim.call(filterField)
      if (filterField !== "") {
        if (rowField !== null && rowField !== undefined) {
          if (rowField >= 0) {
            if (
              filterField === "base" && rowField === 0
            ) {
              return row
            }
            else if (filterField === "advanced" && rowField > 0) {
              return row
            }
          } else {
            return row
          }
        } else {
          // Elemento da scartare
        }
      } else {
        return row
      }
    } else {
      return row
    }
  }

  function AddAdvancedLicense(props) {
    const { keyToUse, adminTableRowData, rows, setRows } = props
    const [openAdvancedLicense, setOpenAdvancedLicense] = useState(false)

    // Click Routing Job
    function handleOpenAdvancedLicense(event) {
      setOpenAdvancedLicense(true)
    }

    // Click Close Job
    function handleCloseAdvancedLicense() {
      setOpenAdvancedLicense(false)
    }

    return (
      <TableCell key={"tableBodyCellLicense-" + keyToUse} align="left">
        <AdminTableAdvancedLicenseButton
          deleteButton={keyToUse}
          handleClickOpen={handleOpenAdvancedLicense} />
        {openAdvancedLicense ?
          (<DialogAdvancedLicense
            openAdvancedLicense={openAdvancedLicense}
            handleClickClose={handleCloseAdvancedLicense}
            adminTableRowData={adminTableRowData}
            adminTableRows={rows}
            setAdminTableRows={setRows}
          >
          </DialogAdvancedLicense>
          ) : null
        }

      </TableCell>
    )

  }

  // Finestra License company
  function DialogAdvancedLicense(props) {
    const { openAdvancedLicense, handleClickClose } = props
    return (
      openAdvancedLicense ? (
        <Dialog
          onClose={handleClickClose}
          aria-labelledby="customized-dialog-title"
          open={openAdvancedLicense}
          maxWidth="xl"
        >
          <DialogContent>
            <LicenseAdvanced {...props} />
          </DialogContent>
        </Dialog>
      ) : (
        null
      )
    )
  }

  //Scrive i moduli advanced di una licenza
  function writeAdvanced(advancedLicense) {
    var res = ""
    if (advancedLicense !== undefined) {
      if (advancedLicense.length >= 0) {
        advancedLicense.map((option) => { return res += "- " + option.advancedId.name + "\r\n" })
        return res
      }

    }
    return res
  }

  //Funzione creare il file PDF.
  function exportPDF(rows, order, orderBy) {

    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    var data
    today = dd + '/' + mm + '/' + yyyy
    const unit = "pt"

    const size = "A4" // Use A1, A2, A3 or A4
    const orientation = "landscape" // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = t("10258").toUpperCase() + " " + today
    const licenseSituation = t("10230").toUpperCase() + ":" + allLicenses.total + " - " + t("10231").toUpperCase() + ": " + allLicenses.used + " - "
      + t("10232").toUpperCase() + ":" + allLicenses.available
      + " (" + t("10286") + allLicenses.free + t("10287") + " )"


    const headers = [[
      t("10224").toUpperCase(),  //IdLicenza
      t("10225").toUpperCase(),  //Chiave licenza
      t("10200").toUpperCase(),  //Data creazione
      t("10047").toUpperCase(),  //Data attivazione 
      t("10117").toUpperCase(),  //Company 
      t("10257").toUpperCase(),   //Città - Provincia 
      t("10281").toUpperCase(),   //Moduli advanced 
    ]]


    let tableFiltered = FilterAdminTableCells(rows)
    tableFiltered = stableSort(tableFiltered, getComparator(order, orderBy))
    data = tableFiltered.map(elt =>
      [elt.id, elt.licenseKey, DatesUtils.truncateDateForPdf(elt.creationDate), DatesUtils.truncateDateForPdf(elt.activationDate), elt.company, elt.city, writeAdvanced(elt.licenseAdvanceds)]
    )

    let content = {
      startY: 60,
      head: headers,
      body: data
    }

    doc.text(title, marginLeft, 30)
    doc.setFontSize(10)
    doc.text(licenseSituation, marginLeft, 50)
    doc.autoTable(content)
    doc.save(title + ".pdf")
  }

  useEffect(() => {
    if (allRetailers.length > 0) {
      var newFilter = filter
      var filterRetailers = allRetailers.map((option) => {
        return {
          value: option.retailerId,
          label: option.retailerId + " - " + option.name,
        }
      })
      filterRetailers.sort(function (a, b) {
        if (parseInt(a.value) >= parseInt(b.value)) {
          return 1
        } else {
          return -1
        }
      })
      filterRetailers.unshift({
        value: "",
        label: "",
      })
      newFilter.map(filter => {
        filter.selectOption = filter.id === "retailerId" ? filterRetailers : filter.selectOption
        filter.value = filter.id === "retailerId" ? defaultRetailerId : filter.value
        return filter
      })
      setFilter(newFilter)
    }
  }, [allRetailers, filter, defaultRetailerId])


  return (
    <div>
      <LicensesCountTable headerWord={t("10223")} totLicenses={allLicenses} />
      <AdminTable
        AdminTableCells={AdminTableCells}
        AdminTableCustomCount={LicensesCount}
        AdminTableCustomCountTable={LicensesCountTable}
        allRetailers={allRetailers}
        createData={createData}
        disableAdd={true}
        disableOpen={true}
        disableDelete={true}
        downloadData={true}
        defaultRowsPerPage={10}
        exportPDF={exportPDF}
        filterToolbarExpaned={true}
        filter={filter}
        FilterAdminTableCells={FilterAdminTableCells}
        headCells={headCells}
        isFullTable={true}
        getAdminTableKey={getAdminTableKey}
        getAdminTableCellsCount={getAdminTableCellsCount}
        orderByDefault={orderByDefault}
        urlAccessCode={urlAccessCode}
        urlGet={urlGet}
        useCompany={false}
      />
    </div>
  )
}