import React, { useContext, useEffect, useState } from 'react'
import { Switch, Redirect } from "react-router-dom";
import { RootContext } from '../RootContext'
import { PrivateRoute } from "../index";
import { useTranslation } from "react-i18next"

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

// pegaso components
import { dashboardRoutesFull, dashboardRoutesPartial, dashboardRoutesPartial_2 } from "routes.js";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import bgImage from "assets/img/sidebar-2.jpg";

let ps;

function switchRoutes(routes) {
  return (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === "/admin") {
          return (
            <PrivateRoute
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      }
      )}
      <Redirect from="/admin" to={`/admin/opticianUsers`} />
    </Switch>
  );
}
const useStyles = makeStyles(styles);


export default function Admin({ ...rest }) {
  const { companyName, isRetailerUser, getRetailerId, getRetailer, getLanguage } = useContext(RootContext)

  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image] = useState(bgImage);
  const [color] = useState("blue");
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isRetailer] = useState(isRetailerUser())
  const [retailerId] = useState(getRetailerId())
  const { i18n } = useTranslation()
  const userLanguage = getLanguage()
  const [retailer] = useState(getRetailer())
  const isIndependent = retailer ? retailer.isIndependent ? retailer.isIndependent : false : false

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  function viewLink() {
    let result = `/resources/images/administrator.ico`
    if (isRetailer) {
      switch (retailerId) {
        case 2:
          result = `/resources/images/IT6snOeA.ico`
          break;
        default:
          break;
      }
    }
    return result
  }

  let routes = dashboardRoutesFull
  if (isRetailer) {
    routes = dashboardRoutesPartial
    if(isIndependent){
      routes = dashboardRoutesPartial_2
    }
  }

  // Imposto lingua del utente
  useEffect(() => {
    i18n.changeLanguage(userLanguage)
  }, [i18n, userLanguage])

  // initialize and destroy the PerfectScrollbar plugin
  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={companyName}
        logo={viewLink()}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes(routes)}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes(routes)}</div>
        )}
        {getRoute() ? <Footer /> : null}
      </div>
    </div>
  );
}
