import React, { useState, useContext, useEffect } from "react"
import { RootContext } from "RootContext"

//pegaso views/components
import AdminTable from "components/AdminTable/AdminTable"
import AdminTableUtils from "components/AdminTable/AdminTableUtils"
import LicenseGenerator from "views/LicenseGenerator/LicenseGenerator"
import UserUtils from "components/Utils/UserUtils"
import { LicensesCount, LicensesCountTable } from "components/Licenses/LicensesCount"

// core components
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"

// Nomi dei campi delle colonne
function createData(id, licenseKey, retailer, creationDate, activationDate, active, company) {
  return {
    id,
    licenseKey,
    retailer,
    creationDate,
    activationDate,
    active,
    company
  }
}

export default function LicensesGenerator() {
  const {
    t,
    isRetailerUser,
    getRetailerId,
    apiUrl,
    allRetailers } = useContext(RootContext)
  // Variabili
  
  const [isRetailer] = useState(isRetailerUser())
  const [retailerId] = useState(getRetailerId())

  // Imposto retailer di default (se non è retailerUser metto 2-Luneau)
  let defaultRetailerId = 2
  if (isRetailerUser) {
    if (retailerId) {
      defaultRetailerId = retailerId
    }
  }

  const [filter, setFilter] = useState([
    {
      id: "id",
      value: "",
      label: "id",

    },
    {
      id: "retailerId",
      value: defaultRetailerId,
      isSelect: true,
      selectOption: [],
      label: t("10226"),
      disabled: isRetailer,
    },
    {
      id: "company",
      value: "",
      label: t("10117"),
    },
  ])

  const headCells = [
    { id: "id", numeric: true, disablePadding: false, label: t("10224").toUpperCase(), },
    { id: "licenseKey", numeric: false, disablePadding: false, label: t("10225").toUpperCase(), },
    { id: "retailerIdSort", numeric: false, disablePadding: false, label: t("10226").toUpperCase(), },
    { id: "creationDate", numeric: false, disablePadding: false, label: t("10200").toUpperCase() },
    { id: "activationDate", numeric: false, disablePadding: false, label: t("10227").toUpperCase() },
    { id: "active", numeric: false, disablePadding: false, label: t("10047").toUpperCase() },
    { id: "company", numeric: false, disablePadding: false, label: t("10117").toUpperCase() },
  ]

  const [numLicenses, setNumLicenses] = useState({
    total: 0,
    used: 0,
    available: 0,
  })

  const [allLicenses, setAllLicenses] = useState({
    total: 0,
    used: 0,
    available: 0,
    free: 0,
  })

  const orderByDefault = "id"
  const [urlAccessCode] = useState()
  const urlGet = `${apiUrl}/list-licenses`

  /*************************************
   *
   * Cells
   *
   *************************************/

  // Quale campo è la chiave
  function getAdminTableKey(row) {
    return row.id
  }

  // Numero di colonne della tabella
  function getAdminTableCellsCount() {
    return 7
  }

  // Contenuto Celle
  function AdminTableCells(props) {
    const {
      keyToUse,
      labelId,
      row,
    } = props
    const key = keyToUse

    row.creationDate = row.creationDate !== undefined ? row.creationDate : ""
    row.activationDate = row.activationDate !== undefined ? row.activationDate : ""
    row.company = row.company !== undefined ? row.company : ""

    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow-" + key} >
        <TableCell
          key={"tableBodyCell0-" + key}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          {key ? key : ""}
        </TableCell>
        <TableCell key={"tableBodyCell1-" + key} align="left">
          {row.licenseKey ? row.licenseKey : ""}
        </TableCell>
        <TableCell key={"tableBodyCell4-" + key} align="left">
          {UserUtils.formatRetailer(row)}
        </TableCell>
        <TableCell key={"tableBodyCell5-" + key} align="left">
          {row.creationDate ? row.creationDate : ""}
        </TableCell>
        <TableCell key={"tableBodyCell6-" + key} align="left">
          {row.activationDate ? row.activationDate : ""}
        </TableCell>
        <TableCell key={"tableBodyCell7-" + key} align="left">
          {row.active !== undefined ? (row.active ? "True" : "False") : ""}
        </TableCell>
        <TableCell key={"tableBodyCell8-" + key} align="left">
          {row.company ? row.company : ""}
        </TableCell>
      </TableRow>
    )
  }

  // Filtrare contenuto Celle
  function FilterAdminTableCells(rows) {
    const result = rows.length > 0
      ? rows.filter((row) => {
        return AdminTableUtils.filterField(
          row,
          row.id,
          filter.filter((filter) => {
            return filter.id === "id"
          })[0].value
        )
      })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            UserUtils.formatRetailer(row),
            filter.filter((filter) => {
              return filter.id === "retailerId"
            })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.company,
            filter.filter((filter) => {
              return filter.id === "company"
            })[0].value
          )
        })
      : rows

    if (result.length > 0) {
      if (result) {
        let resultLicenses = LicensesCount(result)
        if (resultLicenses.total > 0) {
          if (resultLicenses.total !== numLicenses.total)
            setNumLicenses({ ...numLicenses, total: resultLicenses.total })
          if (resultLicenses.available !== numLicenses.available)
            setNumLicenses({ ...numLicenses, available: resultLicenses.available })
          if (resultLicenses.used !== numLicenses.used)
            setNumLicenses({ ...numLicenses, used: resultLicenses.used })
        }
      }
    } else {
      let resultLicenses = { total: 0, available: 0, used: 0 }
      if (resultLicenses.total !== numLicenses.total) {
        setNumLicenses(resultLicenses)
      }
    }

    // 2023/09/08 - Testata TUTTE licenze
    const resultAll = rows.length > 0
      ? rows.filter((row) => {
        return AdminTableUtils.filterField(
          row,
          UserUtils.formatRetailer(row),
          filter.filter((filter) => {
            return filter.id === "retailerId"
          })[0].value
        )
      })
      : rows

    if (resultAll.length > 0) {
      if (resultAll) {
        let resultLicensesAll = LicensesCount(resultAll)
        if (resultLicensesAll.total > 0) {
          if (resultLicensesAll.total !== allLicenses.total)
            setAllLicenses({ ...allLicenses, total: resultLicensesAll.total })
          if (resultLicensesAll.available !== allLicenses.available)
            setAllLicenses({ ...allLicenses, available: resultLicensesAll.available })
          if (resultLicensesAll.used !== allLicenses.used)
            setAllLicenses({ ...allLicenses, used: resultLicensesAll.used })
          if (resultLicensesAll.free !== allLicenses.free)
            setAllLicenses({ ...allLicenses, free: resultLicensesAll.free })
        }
      }
    } else {
      let resultLicensesAll = { total: 0, available: 0, used: 0, free: 0 }
      if (resultLicensesAll.total !== allLicenses.total) {
        setAllLicenses(resultLicensesAll)
      }
    }

    return result
  }

  /*************************************
   *
   * UseEffect
   *
   *************************************/
  useEffect(() => {
    if (allRetailers.length > 0) {
      var newFilter = filter
      var filterRetailers = allRetailers.map((option) => {
        return {
          value: option.retailerId,
          label: option.retailerId + " - " + option.name,
        }
      })
      filterRetailers.sort(function (a, b) {
        if (parseInt(a.value) >= parseInt(b.value)) {
          return 1
        } else {
          return -1
        }
      })
      filterRetailers.unshift({
        value: null,
        label: "",
      })
      newFilter.map(filter => {
        filter.selectOption = filter.id === "retailerId" ? filterRetailers : filter.selectOption
        filter.value = filter.id === "retailerId" ? defaultRetailerId : filter.value
        return filter
      })
      setFilter(newFilter)
    }
  }, [allRetailers, defaultRetailerId, filter])

  return (
    <div>
      <LicensesCountTable headerWord={t("10245")} totLicenses={allLicenses} />
      <AdminTable
        AddEditElement={LicenseGenerator}
        AdminTableCells={AdminTableCells}
        AdminTableCustomCount={LicensesCount}
        AdminTableCustomCountTable={LicensesCountTable}
        allRetailers={allRetailers}
        createData={createData}
        defaultRowsPerPage={10}
        disableDelete={true}
        filter={filter}
        FilterAdminTableCells={FilterAdminTableCells}
        headCells={headCells}
        isFullTable={true}
        getAdminTableKey={getAdminTableKey}
        getAdminTableCellsCount={getAdminTableCellsCount}
        orderByDefault={orderByDefault}
        urlAccessCode={urlAccessCode}
        urlGet={urlGet}
        useCompany={false}
      />
    </div>
  )
}