import React, { useState, useContext, useCallback, useEffect } from "react"
import { RootContext } from "RootContext"

//pegaso views/components
import AdminTable from "components/AdminTable/AdminTable"
import AdminTableUtils from "components/AdminTable/AdminTableUtils"
import RetailerUser from "views/RetailerUser/RetailerUser"
import UserUtils from "components/Utils/UserUtils"

// core components
import IconButton from "@material-ui/core/IconButton"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import Tooltip from "@material-ui/core/Tooltip"
import VisibilityIcon from "@material-ui/icons/Visibility"

// Nomi dei campi delle colonne
function createData(username, password, retailer, language, utc, email, active, description, telephone) {
  return {
    username,
    password,
    retailer,
    language,
    utc,
    email,
    active,
    description,
    telephone,
  }
}

// Map delle righe quando viene aggiunto/modificato una riga
function remapGetRows(rows) {
  return rows.map((elem) => {
    let newElem = elem
    newElem.viewPassword = false
    return newElem
  })
}

// Map delle righe quando viene eliminato una riga
function remapDeleteRow(rows, rowToDelete) {
  return rows.filter((elem) => {
    return elem.username !== rowToDelete
  })
}

// Click View Password
const handleClickViewPassword = (rows, setRows, rowToViewPassword) => {
  setRows(
    rows.map((elem) => {
      let newElem = elem
      if (elem === rowToViewPassword) {
        newElem.viewPassword = !newElem.viewPassword
      }
      return newElem
    })
  )
}

export default function RetailerUsers() {
  const {
    t,
    isRetailerUser,
    getRetailerId,
    apiUrl,
    allRetailers
  } = useContext(RootContext)


  // Variabili
  const [isRetailer] = useState(isRetailerUser())
  const [retailerId] = useState(getRetailerId())
  const [changeCompany, setChangeCompany] = useState()

  const [filter, setFilter] = useState([
    {
      id: "username",
      value: "",
      label: t("10062"),
    },
    {
      id: "retailerId",
      value: retailerId ? retailerId : "",
      defaultValue: retailerId ? retailerId : "",
      isSelect: true,
      selectOption: [],
      label: t("10226"),
      disabled: isRetailer,
    },
  ])

  const headCells = [
    { id: "username", numeric: false, disablePadding: false, label: t("10062").toUpperCase(), },
    { id: "password", numeric: false, disablePadding: false, label: t("10063").toUpperCase(), },
    { id: "retailerIdSort", numeric: false, disablePadding: false, label: t("10226").toUpperCase(), },
    { id: "language", numeric: false, disablePadding: false, label: t("10083").toUpperCase(), },
    { id: "utc", numeric: true, disablePadding: false, label: t("10111").toUpperCase(), },
    { id: "active", numeric: false, disablePadding: false, label: t("10047").toUpperCase(), },
    { id: "descriptionView", numeric: false, disablePadding: false, label: t("10110").toUpperCase(), },
    { id: "telephone", numeric: false, disablePadding: false, label: t("10149").toUpperCase(), },
  ]
  const orderByDefault = "username"
  const [urlAccessCode] = useState()
  const urlGet = `${apiUrl}/list-retailer-users`
  const urlDelete = `${apiUrl}/retailer-users/`

  /*************************************
   *
   * Cells
   *
   *************************************/

  // Quale campo è la chiave
  function getAdminTableKey(row) {
    return row.username
  }

  // Numero di colonne della tabella
  function getAdminTableCellsCount() {
    return 7
  }

  // Contenuto Celle
  function AdminTableCells(props) {
    const {
      CellButtonEdit,
      CellButtonDelete,
      keyToUse,
      labelId,
      row,
      rows,
      setRows,
    } = props
    const key = keyToUse

    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow-" + key} >
        <TableCell
          key={"tableBodyCell0-" + key}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          {key ? key : ""}
        </TableCell>
        <TableCell key={"tableBodyCell1-" + key} align="left">
          {row.password
            ? row.viewPassword
              ? row.password
              : "*".repeat(row.password.length)
            : ""}
          <Tooltip
            title={row.viewPassword ? t("10112") : t("10113")}
          >
            <IconButton
              aria-label={
                row.viewPassword ? t("10112") : t("10113")
              }
              variant="outlined"
              onClick={() => handleClickViewPassword(rows, setRows, row)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell key={"tableBodyCell3-" + key} align="left">
          {UserUtils.formatRetailer(row)}
        </TableCell>
        <TableCell key={"tableBodyCell4-" + key} align="left">
          {row.language ? row.language : ""}
        </TableCell>
        <TableCell key={"tableBodyCell5-" + key} align="left">
          {row.utc ? row.utc : row.utc === 0 ? row.utc : ""}
        </TableCell>
        <TableCell key={"tableBodyCell6-" + key} align="left">
          {row.active !== undefined ? (row.active ? "True" : "False") : ""}
        </TableCell>
        <TableCell key={"tableBodyCell7-" + key} align="left">
          {UserUtils.formatDescription(row)}
        </TableCell>
        <TableCell key={"tableBodyCell8-" + key} align="left">
          {row.telephone ? row.telephone : ""}
        </TableCell>
        <CellButtonEdit keyToUse={keyToUse} />
        <CellButtonDelete keyToUse={keyToUse} />
      </TableRow>
    )
  }

  // Filtrare contenuto Celle
  function FilterAdminTableCells(rows) {
    return rows.length > 0
      ? rows
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.username,
            filter.filter((filter) => {
              return filter.id === "username"
            })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.retailer.retailerId,
            filter.filter((filter) => {
              return filter.id === "retailerId"
            })[0].value
          )
        })
      : rows
  }

  /*************************************
   *
   * Fetch
   *
   *************************************/

  //Fetch -  Dati optician per numero di telefono
  const fetchAdministratorUser = useCallback((username, rows, setData, setActionFetch) => {
    let userToFetch = rows.filter(function (elem) {
      return elem.username === username
    })[0]
    setData(userToFetch)
    setActionFetch(false)
  }, [])

  /*************************************
 *
 * UseEffect
 *
 *************************************/
   useEffect(() => {
    if (allRetailers.length > 0) {
      var newFilter = filter
      var filterRetailers = allRetailers.map((option) => {
        return {
          value: option.retailerId,
          label: option.retailerId + " - " + option.name,
        }
      })
      filterRetailers.sort(function (a, b) {
        if (parseInt(a.value) >= parseInt(b.value)) {
          return 1
        } else {
          return -1
        }
      })
      filterRetailers.unshift({
        value: "",
        label: "",
      })
      newFilter.map(filter => {
        filter.selectOption = filter.id === "retailerId" ? filterRetailers : filter.selectOption
        return filter
      })
      setFilter(newFilter)
    }
  }, [allRetailers, filter])

  return (
    <div>
      <AdminTable
        AddEditElement={RetailerUser}
        AdminTableCells={AdminTableCells}
        allRetailers={allRetailers}
        changeCompany={changeCompany}
        createData={createData}
        defaultRowsPerPage={10}
        filter={filter}
        FilterAdminTableCells={FilterAdminTableCells}
        handleClickOpenAction={fetchAdministratorUser}
        handleClickViewPassword={handleClickViewPassword}
        headCells={headCells}
        isFullTable={true}
        getAdminTableKey={getAdminTableKey}
        getAdminTableCellsCount={getAdminTableCellsCount}
        orderByDefault={orderByDefault}
        remapDeleteRow={remapDeleteRow}
        remapGetRows={remapGetRows}
        setChangeCompany={setChangeCompany}
        urlAccessCode={urlAccessCode}
        urlDelete={urlDelete}
        urlGet={urlGet}
        useCompany={false}
      />
    </div>
  )
}
