import React, { useState, useContext, useEffect, useCallback, Fragment } from "react"
import { RootContext } from "RootContext"

//pegaso/views/components
import { fetchWithToken } from "components/Fetch/api-fetch"
import {
  AdminTableElevationScroll,
  AdminTableOkSaved,
  AdminTableSaveButton,
} from "components/AdminTable/AdminTableEditScreen"
import ErrorUtils from "components/Tools/ErrorUtils"
import CompanyUtils from "components/Utils/CompanyUtils"
import JobUtils from "components/Utils/JobUtils"
import RetailerUtils from "components/Utils/RetailerUtils"
import References from "views/References/References"
import StringUtils from "components/Tools/StringUtils"
import UserUtils from "components/Utils/UserUtils"

//core components
import Autocomplete from "@material-ui/lab/Autocomplete"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import Checkbox from "@material-ui/core/Checkbox"
import CircularProgress from "@material-ui/core/CircularProgress"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import { v4 as uuidv4 } from "uuid" //Creazione del clientId --> richiamare uuidv4()

//import CSS
import {
  useAdminTableEditScreenStyles,
  useStylesText,
} from "styles/adminTable/adminTableEditScreenCss"
import { useAutocompleteStyles } from "styles/autocomplete/autocompleteCss"



function EdgingGridContainer(props) {
  const {
    adminTableRowData,
    adminTableRows,
    allScopes,
    allRetailers,
    companyLabs,
    creation,
    handleClickClose,
    scopesdescription,
    setAdminTableRows,
  } = props
  const {
    t,
    isRetailerUser,
    apiUrl,
    access_token,
    refresh_token,
    setAccess_token,
    getLensTypes,
    getRetailerId,
    setAllFilterCompanies
  } = useContext(RootContext)

  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()
  const classesText = useStylesText()
  const classesAutocomplete = useAutocompleteStyles()

  //Variabili
  const [isRetailer] = useState(isRetailerUser())
  const [dialogMsg, setDialogMsg] = useState("")
  const [isSaving, setIsSaving] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  //Costanti tipo lenti e scope
  const languageList = UserUtils.languageList
  const lensTypes = getLensTypes()

  //Valori dei Dati
  const [company, setCompany] = useState(
    creation
      ? {
        companyId: CompanyUtils.countCompanyId(adminTableRows).toString(),
        name: undefined,
        active: true,
        companySuffix: undefined,
        urlAccessCode: UserUtils.generatePasswordLettersNumbers(),
        address: {
          street: "",
          number: "",
          city: "",
          province: "",
          state: "",
          zipCode: "",
        },
        references: [],
        companyScopes: [],
        lensTypes: ["SV", "BI", "PR"],
        vatNumber: "",
        email: undefined,
        footer: {
          site: "",
          telephoneNumber: "",
          email: "",
          mobilePhone: "",
        },
        license: {
          id: undefined,
          activationDate: undefined,
          active: false,
          creationDate: undefined,
          licenseKey: undefined,
          retailer: {
            availableBrands: [],
            companyDefaultScopes: [],
            name: undefined,
            retailerId: undefined
          }
        },
        language: "it-it",
      }
      : {
        companyId: adminTableRowData.companyId,
        name: adminTableRowData.name,
        active: adminTableRowData.active,
        companySuffix: adminTableRowData.companySuffix,
        urlAccessCode: adminTableRowData.urlAccessCode,
        address: {
          street: adminTableRowData.address.street,
          number: adminTableRowData.address.number,
          city: adminTableRowData.address.city,
          province: adminTableRowData.address.province,
          state: adminTableRowData.address.state,
          zipCode: adminTableRowData.address.zipCode,
        },
        references: adminTableRowData.references,
        companyScopes: adminTableRowData.companyScopes,
        lensTypes: CompanyUtils.getArrayLens(adminTableRowData.lensTypes),
        vatNumber: adminTableRowData.vatNumber,
        email: adminTableRowData.email,
        subscribedOn: adminTableRowData.subscribedOn,
        footer: adminTableRowData.footer,
        license: adminTableRowData.license,
        language: adminTableRowData.language,
      }
  )
  /*************************************
   *
   * Handler per modificare i valori
   *
   *************************************/
  //Cambia field
  const handleChange = (name) => (event) => {
    setCompany({ ...company, [name]: event.target.value })
  }

  //Elimina gli spazi finali dai campi
  const onBlurTrim = (companyParameter, name) => (event) => {
    setCompany({ ...company, [name]: companyParameter.trim() })
  }

  //Cambia field (checkbox)
  const handleChangeChecked = (name) => (event) => {
    setCompany({ ...company, [name]: event.target.checked })
  }

  //Cambia Company.Address field
  const handleChangeAddress = (name) => (event) => {
    setCompany({
      ...company,
      address: { ...company.address, [name]: event.target.value },
    })
  }

  //Elimina gli spazi finali dai campi
  const onBlurTrimAddress = (addressParameter, name) => (event) => {
    setCompany({
      ...company,
      address: { ...company.address, [name]: addressParameter.trim() }
    })
  }

  //Cambia License.Retailer field
  const handleChangeRetailer = (name) => (event) => {
    let newLicenseKey = ""
    let newSelectedScopes = company.companyScopes
    let newRetailer = undefined
    let newCompanySuffix = company.companySuffix
    let newCompanyName = company.name

    if (event.target.value !== undefined) {
      // Imposto nuova licenseKey
      newLicenseKey = uuidv4()

      // Scorro i retailers
      allRetailers.map((elem) => {
        if (elem.retailerId === event.target.value) {
          // Imposto retailer
          newRetailer = elem

          // Imposto companyScopes
          if (company.companyScopes.length === 0) {
            newSelectedScopes = []
            if (elem.companyDefaultScopes !== undefined) {
              if (elem.companyDefaultScopes.length > 0) {
                elem.companyDefaultScopes.map((elemScope) => {
                  newSelectedScopes.push({
                    scopeId: elemScope.scopeTypeId,
                    active: true,
                  })
                  return elemScope
                })
              }
            }
          }
        }
        return elem
      })

      // Creo companySuffix automatico se Retailer <> Pegaso
      if (event.target.value >= 2) {
        newCompanySuffix = RetailerUtils.getRetailerSuffixLetter(event.target.value) + "001"
        // Cerco quello più alto già esistente, e sommo 1
        adminTableRows.map((elem) => {
          if (elem.license.retailer.retailerId === event.target.value) {
            newCompanySuffix = JobUtils.GetBiggestSuffix(newCompanySuffix, elem.companySuffix)
          }
          return elem
        })
        newCompanyName = "Libero " + newCompanySuffix
      } else {
        newCompanySuffix = ""
        newCompanyName = ""
      }

      setCompany(
        company.license !== undefined ?
          {
            ...company,
            name: newCompanyName,
            companySuffix: newCompanySuffix,
            companyScopes: newSelectedScopes,
            license: {
              ...company.license,
              licenseKey: newLicenseKey,
              active: false,
              retailer: newRetailer,
            },
          }
          :
          {
            ...company,
            companySuffix: newCompanySuffix,
            companyScopes: newSelectedScopes,
            license: {
              licenseKey: newLicenseKey,
              active: false,
              retailer: newRetailer,
            },
          }
      )
    }
  }

  //Cambia Company.Footer field
  const handleChangeFooter = (name) => (event) => {
    setCompany({
      ...company,
      footer: { ...company.footer, [name]: event.target.value },
    })
  }

  //Elimina gli spazi finali dai campi
  const onBlurTrimFooter = (footerParameter, name) => (event) => {
    setCompany({
      ...company,
      footer: { ...company.footer, [name]: footerParameter.trim() }
    })
  }

  //Close
  const handleClose = () => {
    setOpenDialog(false)
    handleClickClose()
  }

  //Salvataggio dei dati
  const handleSave = (event) => {
    setIsSaving(true)
    const url = `${apiUrl}/companies/${company.urlAccessCode}`
    var prepareMail = undefined
    if (company.email) {
      if (company.email.trim().length > 0) {
        prepareMail = company.email
      }
    }
    fetchWithToken(url, {
      method: creation ? "POST" : "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        companyId: company.companyId,
        name: company.name,
        active: company.active,
        companySuffix: company.companySuffix,
        urlAccessCode: company.urlAccessCode,
        address: {
          street: company.address.street,
          number: company.address.number,
          city: company.address.city,
          province: company.address.province,
          state: company.address.state,
          zipCode: company.address.zipCode,
        },
        references: company.references,
        companyScopes: company.companyScopes,
        lensTypes: company.lensTypes,
        vatNumber: company.vatNumber,
        email: prepareMail,
        footer: company.footer,
        license: {
          licenseKey: company.license.licenseKey,
          active: company.license.active,
          retailer: company.license.retailer.retailerId,
        },
        language: company.language,
      }),
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then((data) => {
        let msgDetails = StringUtils.generateNewLines(
          `${t("10119")}\n\n CompanyId: ${company.companyId}\n Name: ${company.name
          }\n UrlAccessCode: ${company.urlAccessCode}`
        )
        setDialogMsg(msgDetails)
        setOpenDialog(true)
        setIsSaving(false)
        // Aggiorna la lista utenti
        let newElem = {
          companyId: company.companyId,
          name: company.name,
          active: company.active,
          companySuffix: company.companySuffix,
          urlAccessCode: company.urlAccessCode,
          address: {
            street: company.address.street,
            number: company.address.number,
            city: company.address.city,
            province: company.address.province,
            state: company.address.state,
            zipCode: company.address.zipCode,
          },
          references: company.references,
          companyScopes: company.companyScopes,
          lensTypes: CompanyUtils.createArrayTable(company.lensTypes),
          vatNumber: company.vatNumber,
          email: prepareMail,
          subscribedOn: company.subscribedOn,
          footer: company.footer,
          license: company.license,
          language: company.language,
        }
        let newAdminTableRows = creation
          ? adminTableRows.concat(newElem)
          : adminTableRows.map((elem) => {
            if (elem.companyId === company.companyId) {
              return newElem
            } else {
              return elem
            }
          })
        setAdminTableRows(newAdminTableRows)
        fetchCompanies()
      })
      .catch((err) => {
        ErrorUtils.errorLog(t("22"), err)
        setDialogMsg(t("22"))
        setOpenDialog(true)
        setIsSaving(false)
      })
  }


  const fetchCompanies = () => {
    var retailerId = getRetailerId()
    var reduced = false;
    var scopeId = -1;
    const url = `${apiUrl}/companies?reduced=${reduced}&scopeId=${scopeId}&retailer=${retailerId}`
    fetchWithToken(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then(({ companies }) => {
        companies.sort(function (a, b) {
          if (parseInt(a.companyId) >= parseInt(b.companyId)) {
            return 1
          } else {
            return -1
          }
        })
        if (isRetailerUser()) {
          companies = companies.filter(elem => {
            return elem.license.retailer.retailerId === retailerId
          })
        }
        setAllFilterCompanies(companies)
      })
      .catch((err) => {
        throw Error("FetchAllFilterCompanies Error -> " + err)
      })
  }

  //Prepara l'array per le lenti selezionate dalla company
  function getValueLens() {
    var lensCompany = lensTypes.filter((type) => {
      return company.lensTypes.indexOf(type.value) >= 0
    }
    )
    return lensCompany
  }

  //Prepara l'array per gli scope selezionate dalla company
  function getScopeValue() {
    var mapScope = company.companyScopes.map((option) => { return option.scopeId })
    var scopeCompany = scopesdescription.filter((scope) => {
      return mapScope.indexOf(scope.scopeId) >= 0
    })
    return scopeCompany
  }

  //Setta i campi dello scope optician catalogue with different prices
  // option.scopeTypeId === 5 --> scopeType catalogue with different prices
  // option.scopeId === 7 --> scopes con i settings di base del catalogo
  function searchCatalogue() {
    var catalogue = company.companyScopes.filter((option) => option.scopeId === 7)[0]
    if (creation || catalogue === undefined) {
      var catalogueScope = allScopes.filter((option) => { return option.scopeTypeId === 5 })[0]
      catalogueScope = catalogueScope.scopes.filter((option) => { return option.scopeId === 7 })[0]
      return catalogueScope.defaultSettings
    }
    else {
      return catalogue.companySettings
    }
  }

  // Campi obbligatori per abilitare il tasto SAVE
  function disableSave() {
    let result =
      !company.companyId ||
      !company.name ||
      !company.companySuffix ||
      !company.urlAccessCode ||
      !(company.license === undefined ?
        false
        :
        company.license.licenseKey !== undefined)
    return result
  }

  return (
    <div className={classes.root}>
      <Card>
        <CardBody>
          <AdminTableElevationScroll
            {...props}
            handleClickClose={handleClickClose}
          />
          <p align="center">
            <u>{t("10120").toUpperCase()} </u>
          </p>
          <Grid container spacing={2}>
            <Grid className={classes.gridCurrentValues} item xs={12}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <p align="center">
                    <u>{t("10104").toUpperCase()}</u>
                  </p>
                </Grid>

                {/*GRID CONTENENTE I DATI DELLA COMPANY*/}

                {/*RIGA 1*/}

                <Grid item xs={3}>
                  <TextField
                    select
                    required
                    id="retailerId"
                    label={t("10226")}
                    className={classesText.menuItem}
                    disabled={isRetailer || (!creation && company.license !== undefined)}
                    value={company.license ? company.license.retailer.retailerId || "" : ""}
                    onChange={handleChangeRetailer("retailerId")}
                  >
                    {allRetailers
                      .sort(function compare(a, b) {
                        if (a.retailerId < b.retailerId) {
                          return -1
                        }
                        if (a.retailerId > b.retailerId) {
                          return 1
                        }
                        return 0
                      })
                      .map((option) => (
                        <MenuItem key={"retailerId" + option.retailerId} value={option.retailerId}>
                          {option.retailerId + " - " + option.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    required
                    id={"companyId"}
                    label={t("10114")}
                    value={company.companyId || ""}
                    margin="normal"
                    helperText={t("10105")}
                    onChange={handleChange("companyId")}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled={isRetailer}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    required
                    id={"name"}
                    label={t("10079")}
                    onChange={handleChange("name")}
                    onBlur={company.name ? onBlurTrim(company.name, "name") : null}
                    className={classesText.textField}
                    value={company.name || ""}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    required
                    id={"companySuffix"}
                    label={t("10115")}
                    onChange={handleChange("companySuffix")}
                    onBlur={company.companySuffix ? onBlurTrim(company.companySuffix, "companySuffix") : null}
                    helperText={t("10115")}
                    className={classesText.textField}
                    value={company.companySuffix || ""}
                    margin="normal"
                    InputProps={{
                      readOnly: !creation,
                    }}
                    disabled={isRetailer}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    required
                    id={"urlAccessCode"}
                    label={t("10116")}
                    onChange={handleChange("urlAccessCode")}
                    helperText={t("10116")}
                    className={classesText.textField}
                    value={company.urlAccessCode || ""}
                    margin="normal"
                    InputProps={{
                      readOnly: !creation,
                    }}
                    disabled={isRetailer}
                  />
                </Grid>

                {/*RIGA 2*/}
                <Grid item xs={3}>
                  <TextField
                    id={"street"}
                    label={t("10121")}
                    onChange={handleChangeAddress("street")}
                    onBlur={onBlurTrimAddress(company.address.street, "street")}
                    className={classesText.textField}
                    value={company.address.street || ""}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id={"number"}
                    label={t("10122")}
                    onChange={handleChangeAddress("number")}
                    onBlur={onBlurTrimAddress(company.address.number, "number")}
                    className={classesText.textField}
                    value={company.address.number || ""}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id={"city"}
                    label={t("10123")}
                    onChange={handleChangeAddress("city")}
                    onBlur={onBlurTrimAddress(company.address.city, "city")}
                    className={classesText.textField}
                    value={company.address.city || ""}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id={"province"}
                    label={t("10124")}
                    onChange={handleChangeAddress("province")}
                    onBlur={onBlurTrimAddress(company.address.province, "province")}
                    className={classesText.textField}
                    value={company.address.province || ""}
                    margin="normal"
                  />
                </Grid>

                {/*RIGA 3*/}

                <Grid item xs={3}>
                  <TextField
                    id={"zipCode"}
                    label={t("10126")}
                    onChange={handleChangeAddress("zipCode")}
                    onBlur={onBlurTrimAddress(company.address.zipCode, "zipCode")}
                    className={classesText.textField}
                    value={company.address.zipCode || ""}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id={"state"}
                    label={t("10125")}
                    onChange={handleChangeAddress("state")}
                    onBlur={onBlurTrimAddress(company.address.state, "state")}
                    className={classesText.textField}
                    value={company.address.state || ""}
                    margin="normal"
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id={"vatNumber"}
                    label={t("10127")}
                    onChange={handleChange("vatNumber")}
                    onBlur={company.vatNumber ? onBlurTrim(company.vatNumber, "vatNumber") : null}
                    className={classesText.textField}
                    value={company.vatNumber || ""}
                    margin="normal"
                  />
                </Grid>

                {/*RIGA 4*/}


                <Grid item xs={4}>
                  <TextField
                    id={"email"}
                    label={t("10128")}
                    onChange={handleChange("email")}
                    onBlur={company.email ? onBlurTrim(company.email, "email") : null}
                    className={classesText.textField}
                    value={company.email || ""}
                    margin="normal"
                  />
                </Grid>


                <Grid item xs={4}>
                  <TextField
                    select
                    id="language"
                    label={t("10083")}
                    className={classesText.menuItem}
                    value={company.language}
                    onChange={handleChange("language")}
                    helperText={t("10106")}
                  >
                    {languageList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={company.active}
                        onChange={handleChangeChecked("active")}
                        name="active"
                        disabled={isRetailer}
                      />
                    }
                    label={t("10047")}
                  />
                </Grid>

                {/*RIGA 5*/}

                <Grid item xs={6}>
                  <Autocomplete
                    id={"lensTypes"}
                    classes={classesAutocomplete}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    multiple
                    onChange={(event, newValue) => {
                      var selectedLens = []
                      newValue.map((option) => selectedLens.push(option.value))
                      setCompany({ ...company, lensTypes: selectedLens })
                    }}
                    options={lensTypes}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        variant="outlined"
                        label={t("10213")}
                        placeholder="selected"
                      />
                    )}
                    renderOption={(option, { selected }) => (
                      <Fragment>
                        {`${option.value} - ${option.label}`}
                      </Fragment>
                    )}
                    style={{ width: 500, display: isRetailer ? "none" : "block" }}
                    value={getValueLens()}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Autocomplete
                    id={"selectedScopes"}
                    classes={classesAutocomplete}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.scopeId + " - " + option.description}
                    onChange={(event, newValue) => {
                      var selectedScopes = []
                      newValue.map((option) => {
                        if (option.scopeId === 7) {
                          var appCatalogue = searchCatalogue()
                          selectedScopes.push(
                            {
                              scopeId: 7,
                              active: true,
                              companySettings: appCatalogue
                            }
                          )
                        }
                        else {
                          selectedScopes.push(
                            {
                              scopeId: option.scopeId,
                              active: true,
                            }
                          )
                        }
                        return selectedScopes
                      })
                      setCompany({ ...company, companyScopes: selectedScopes })
                    }}
                    options={scopesdescription}
                    multiple
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        variant="outlined"
                        label="selectedScopes"
                        placeholder="selected"
                      />
                    )}
                    renderOption={(option, { selected }) => (
                      <Fragment>
                        {`${option.scopeId} - ${option.description}`}
                      </Fragment>
                    )}
                    style={{ width: 500, display: isRetailer ? "none" : "block" }}
                    value={getScopeValue()}
                  />
                </Grid>

                {/*RIGA 6*/}
                <Grid item xs={12}>
                  <p style={{ display: isRetailer ? "none" : "block" }} align="center">
                    <u>{t("10221").toUpperCase()}</u>
                  </p>
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id={"site"}
                    label={t("10220")}
                    onChange={handleChangeFooter("site")}
                    onBlur={onBlurTrimFooter(company.footer.site, "site")}
                    className={classesText.textField}
                    value={company.footer.site || ""}
                    margin="normal"
                    style={{ display: isRetailer ? "none" : "block" }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id={"footerEmail"}
                    label={t("10108")}
                    onChange={handleChangeFooter("email")}
                    onBlur={onBlurTrimFooter(company.footer.email, "email")}
                    className={classesText.textField}
                    value={company.footer.email || ""}
                    margin="normal"
                    style={{ display: isRetailer ? "none" : "block" }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id={"telephoneNumber"}
                    label={t("10149")}
                    onChange={handleChangeFooter("telephoneNumber")}
                    onBlur={onBlurTrimFooter(company.footer.telephoneNumber, "telephoneNumber")}
                    className={classesText.textField}
                    value={company.footer.telephoneNumber || ""}
                    margin="normal"
                    style={{ display: isRetailer ? "none" : "block" }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id={"mobilePhone"}
                    label={t("10181")}
                    onChange={handleChangeFooter("mobilePhone")}
                    onBlur={onBlurTrimFooter(company.footer.telephoneNumber, "mobilePhone")}
                    className={classesText.textField}
                    value={company.footer.mobilePhone || ""}
                    margin="normal"
                    style={{ display: isRetailer ? "none" : "block" }}
                  />
                </Grid>

              </Grid>
            </Grid>
          </Grid>

          {/*RIGA 7*/}
          {!isRetailer ?
            <Grid item xs={12}>
              <p align="center">
                <u>{t("10129").toUpperCase()}</u>
              </p>
              <References
                company={company}
                companyLabs={companyLabs}
                setCompany={setCompany}
              />
            </Grid>
            :
            null
          }

          {/*BOTTONI PER SALVATAGGIO*/}
          <AdminTableSaveButton
            {...props}
            handleSave={handleSave}
            disabled={disableSave()}
          />
          <AdminTableOkSaved
            {...props}
            dialogMsg={dialogMsg}
            handleClose={handleClose}
            isSaving={isSaving}
            openDialog={openDialog}
          />
        </CardBody>
      </Card>
    </div>
  )
}

// Griglia interna alla finestra del Company
export default function Company(props) {
  const { adminTableRowData, creation } = props
  const {
    apiUrl,
    access_token,
    refresh_token,
    setAccess_token,
  } = useContext(RootContext)
  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: theme.spacing(2),
    },
  }))
  const classes = useStyles()
  const [scopesDescription, setScopesDescription] = useState([])

  //Comparer Function    
  function GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    }
  }

  //Fetch -  Elenco scopes types
  const fetchScopes = useCallback(() => {
    const url = `${apiUrl}/scopes`
    fetchWithToken(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        var scopes = response.body
        scopes.sort(GetSortOrder("scopeId"));
        setScopesDescription(scopes)
        return response.body
      })
      .catch((err) => {
        ErrorUtils.errorLog("FetchScopes  Error", err)
      })
  }, [apiUrl, refresh_token, access_token, setAccess_token])

  /*************************************
     *
     * UseEffect
     *
     *************************************/
  useEffect(() => {
    fetchScopes()
  }, [fetchScopes])
  if (scopesDescription.length > 0) {
    if (adminTableRowData) {
      return <EdgingGridContainer {...props} scopesdescription={scopesDescription} />
    } else if (!adminTableRowData && creation) {
      return <EdgingGridContainer {...props} scopesdescription={scopesDescription} />
    } else {
      return <CircularProgress className={classes.progress} scopesdescription={scopesDescription} />
    }
  }
  else {
    return <CircularProgress className={classes.progress} />
  }
}
