import React, { useState, useContext, useCallback } from "react"
import { RootContext } from "RootContext"
import { v4 as uuidv4 } from "uuid" //Creazione del clientId --> richiamare uuidv4()


//pegaso/views/components
import { CircularIndeterminate } from "views/Loading"
import { fetchWithToken } from "components/Fetch/api-fetch"
import {
  AdminTableElevationScroll,
  AdminTableOkSaved,
  AdminTableSaveButton,
} from "components/AdminTable/AdminTableEditScreen"
import DevicesTable from "views/DevicesTable/DevicesTable"
import ErrorUtils from "components/Tools/ErrorUtils"
import HubUtils from "components/Utils/HubUtils"
import ScopeUtils from "components/Utils/ScopeUtils"
import StringUtils from "components/Tools/StringUtils"
import UserUtils from "components/Utils/UserUtils"

//core components
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import Checkbox from "@material-ui/core/Checkbox"
import CircularProgress from "@material-ui/core/CircularProgress"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"

//import CSS
import {
  useAdminTableEditScreenStyles,
  useStylesText,
} from "styles/adminTable/adminTableEditScreenCss"
import ScopesTable from "views/ScopesTable/ScopesTable"

function EdgingGridContainer(props) {
  const {
    adminTableRowData,
    adminTableRows,
    allBrandsDevices,
    allScopes,
    creation,
    companyName,
    handleClickClose,
    phone,
    setAdminTableRows,
    urlAccessCode,
  } = props
  const {
    t,
    isRetailerUser,
    apiUrl,
    access_token,
    refresh_token,
    setAccess_token,
  } = useContext(RootContext)

  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()
  const classesText = useStylesText()

  //Variabili
  const [isRetailer] = useState(isRetailerUser())
  const [dialogMsg, setDialogMsg] = useState("")
  const [errorFetch, setErrorFetch] = useState(false)
  const [isFetchingLab, setIsFetchingLab] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  //Variabili specifiche dati hub
  const [hub, setHub] = useState(
    creation
      ? {
        active: true,
        ignorePutEdgingData: false,
        description: "Macchine",
        hubId: undefined,
        lab: {
          labId: NaN,
          name: "",
        },
        labPhone: "",
        password: undefined,
      }
      : {
        active: adminTableRowData.active,
        ignorePutEdgingData: adminTableRowData.ignorePutEdgingData,
        clientId: adminTableRowData.clientId,
        description: adminTableRowData.description,
        hubId: adminTableRowData.hubId,
        lab: {
          labId: adminTableRowData.lab.labId,
          name: adminTableRowData.lab.name,
        },
        labPhone: phone,
        password: adminTableRowData.clientSecret,
      }
  )

  const [hubScopesJSON, setHubScopesJSON] = useState(
    adminTableRowData.hubScopes ? adminTableRowData.hubScopes : []
  )

  const [selectedDeviceJSON, setSelectedDeviceJSON] = useState(
    adminTableRowData.hubDevices ? adminTableRowData.hubDevices : []
  )

  /*************************************
   *
   * Fetch
   *
   *************************************/

  const fetchLabId = useCallback(() => {
    setIsFetchingLab(true)
    setErrorFetch(false)
    const url = `${apiUrl}/companies/${urlAccessCode}/labs/${hub.lab.labId}`
    fetchWithToken(url, {
      method: "GET",
      apiUrl: apiUrl,
      urlAccessCode: urlAccessCode,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body // data
      })
      .then((data) => {
        // Se Lab esiste
        setHub({
          ...hub,
          lab: {
            ...hub.lab,
            name: data.name,
          },
          labPhone: data.phone,
          password: UserUtils.generatePasswordLettersNumbers(),
          clientId: uuidv4(),
        })
        setIsFetchingLab(false)
      })
      .catch((err) => {
        // Se Lab non esiste
        if (err.body.code === "E4") {
          setDialogMsg(t("10141") + " " + t("10142"))
          setErrorFetch(true)
        }
        setIsFetchingLab(false)
        ErrorUtils.errorLog(t("23"), err)
      })
  }, [
    access_token,
    apiUrl,
    hub,
    refresh_token,
    setAccess_token,
    t,
    urlAccessCode,
  ])


  /*************************************
   *
   * Handler per modificare i valori
   *
   *************************************/

  //Cambia field
  const handleChange = (name) => (event) => {
    setHub({ ...hub, [name]: event.target.value })
  }

  //Elimina gli spazi finali dai campi
  const onBlurTrim = (hubParameter, name) => (event) => {
    setHub({ ...hub, [name]: hubParameter.trim() })
  }

  //Cambia field (checkbox)
  const handleChangeChecked = (name) => (event) => {
    setHub({ ...hub, [name]: event.target.checked })
  }

  //Cambia Hub.Lab field (int)
  const handleChangeLabInt = (name) => (event) => {
    setHub({
      ...hub,
      lab: { ...hub.lab, [name]: parseInt(event.target.value) },
    })
  }

  const onBlurLabId = () => {
    if (isNaN(hub.lab.labId)) {
      setHub({
        ...hub,
        lab: {
          ...hub.lab,
          name: "",
        },
        labPhone: "",
        password: "",
        username: "",
        clientId: "",
      })
    } else {
      fetchLabId()
    }
  }

  //Close
  const handleClose = () => {
    setOpenDialog(false)
    handleClickClose()
  }

  //Salvataggio dei dati
  const handleSave = (event) => {
    setIsSaving(true)
    var devices = selectedDeviceJSON ? UserUtils.createJsonDevices(selectedDeviceJSON, true) : undefined
    var scopes = hubScopesJSON ? ScopeUtils.createJsonScopes(hubScopesJSON) : undefined
    const url = creation
      ? `${apiUrl}/companies/${urlAccessCode}/hubs/register`
      : `${apiUrl}/companies/${urlAccessCode}/hubs/${hub.hubId}`
    fetchWithToken(url, {
      method: creation ? "POST" : "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        active: hub.active,
        ignorePutEdgingData: hub.ignorePutEdgingData,
        description: hub.description,
        clientId: hub.clientId,
        clientSecret: hub.password,
        lab: hub.lab.labId,
        hubDevices: selectedDeviceJSON.length === 0 ? [] : devices,
        hubScopes: scopes,
      }),
      apiUrl: apiUrl,
      urlAccessCode: urlAccessCode,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then((data) => {
        var id = hub.hubId
        if (creation) {
          id = data.message.split("[")[1].split("]")[0]
          setHub({
            ...hub,
            hubId: id,
          })
        } else {
          id = hub.hubId
        }
        scopes.map((option) => {
          if ("topicId" in option.hubSettings) {
           return  option.hubSettings.topicId !== undefined ? HubUtils.createAwsQueue(urlAccessCode, id, option.hubSettings.topicId) : null
          }
          return undefined
        })
        let msgDetails = StringUtils.generateNewLines(
          `${t("10143")}\n\n
          HubId: ${id}\n 
          ClientId: ${hub.clientId}\n 
          Password: ${hub.password}\n
          Url: ${urlAccessCode}`

        )
        setDialogMsg(msgDetails)
        setOpenDialog(true)
        setIsSaving(false)

        // Aggiorna la lista hub
        let newElem = {
          hubId: id,
          description: hub.description,
          clientId: hub.clientId,
          clientSecret: hub.password,
          lab: {
            labId: hub.lab.labId,
            name: hub.lab.name,
          },
          active: hub.active,
          ignorePutEdgingData: hub.ignorePutEdgingData,
          viewPassword: false,
          hubDevices: selectedDeviceJSON,
        }

        let newAdminTableRows = creation
          ? adminTableRows.concat(newElem)
          : adminTableRows.map((elem) => {
            if (elem.hubId === hub.hubId) {
              return newElem
            } else {
              return elem
            }
          })
        setAdminTableRows(newAdminTableRows)
      })
      .catch((err) => {
        ErrorUtils.errorLog(t("22"), err)
        setDialogMsg(t("22"))
        setOpenDialog(true)
        setIsSaving(false)
      })
  }

  // Campi obbligatori per abilitare il tasto SAVE
  function disableSave() {
    let result =
      !hub.clientId ||
      !hub.password ||
      !hub.lab.labId ||
      !hub.description
    return result
  }

  return (
    <div className={classes.root}>
      <Card>
        <CardBody>
          <AdminTableElevationScroll
            {...props}
            handleClickClose={handleClickClose}
          />
          <p align="center">
            <u>{t("10144").toUpperCase()} </u>
          </p>
          <Grid container spacing={2}>
            <Grid
              className={classes.gridCurrentValues}
              item
              xs={12}
              container
              justify="flex-start"
              alignItems="center"
            >
              {/* prima riga - la somma degli xs non deve superare 12  */}
              <Grid item xs={12}>
                <p>
                  <strong>
                    {t("10145")}: {companyName}
                  </strong>
                </p>
              </Grid>
              {creation ? null : (
                <Grid item xs={11}>
                  <p>
                    <strong>
                      {t("10146")}: {adminTableRowData.lab.labId} -{" "}
                      {adminTableRowData.lab.name}
                    </strong>
                  </p>
                </Grid>
              )}
            </Grid>

            <Grid className={classes.gridCurrentValues} item xs={12}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <p align="center">
                    <u>{t("10104").toUpperCase()}</u>
                  </p>
                </Grid>

                {/*GRID CONTENENTE I DATI DELL'UTENTE*/}

                {creation ? null : (
                  <Grid item xs={3}>
                    <TextField
                      required
                      id={"hubId"}
                      label={t("10147")}
                      value={hub.hubId || ""}
                      margin="normal"
                      helperText={t("10105")}
                      InputProps={{
                        readOnly: true,
                      }}
                      disabled={isRetailer}
                    />
                  </Grid>
                )}

                <Grid item xs={3}>
                  <TextField
                    required
                    id={"clientId"}
                    label={t("10148")}
                    value={hub.clientId || ""}
                    margin="normal"
                    helperText={t("10105")}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled={isRetailer}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    required
                    id={"password"}
                    label={t("10063")}
                    onChange={handleChange("password")}
                    className={classesText.textField}
                    value={hub.password || ""}
                    margin="normal"
                    disabled={isRetailer}
                  />
                </Grid>

                <Grid item xs={3}>
                  {creation ? (
                    isFetchingLab ? (
                      <CircularIndeterminate />
                    ) : (
                      <TextField
                        required
                        error={errorFetch ? true : false}
                        id={"labId"}
                        label={t("10141")}
                        onChange={handleChangeLabInt("labId")}
                        onBlur={onBlurLabId}
                        className={classesText.textField}
                        value={hub.lab.labId || ""}
                        margin="normal"
                        helperText={errorFetch ? dialogMsg : ""}
                        disabled={isRetailer}
                      />
                    )
                  ) : (
                    <TextField
                      required
                      id={"labId"}
                      label={t("10141")}
                      className={classesText.textField}
                      value={hub.lab.labId || ""}
                      margin="normal"
                      helperText={t("10105")}
                      InputProps={{
                        readOnly: creation ? false : true,
                      }}
                      disabled={isRetailer}
                    />
                  )}
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id={"name"}
                    label={t("10079")}
                    className={classesText.textField}
                    value={hub.lab.name || ""}
                    margin="normal"
                    helperText={t("10105")}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled={isRetailer}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    required
                    id={"description"}
                    label={t("10110")}
                    className={classesText.textField}
                    value={hub.description || ""}
                    margin="normal"
                    onChange={handleChange("description")}
                    onBlur={hub.description ? onBlurTrim(hub.description, "description") : null}
                    disabled={isRetailer}
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hub.active}
                        onChange={handleChangeChecked("active")}
                        name="active"
                      />
                    }
                    label={t("10047")}
                    disabled={isRetailer}
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hub.ignorePutEdgingData}
                        onChange={handleChangeChecked("ignorePutEdgingData")}
                        name="ignorePutEdgingData"
                      />
                    }
                    label={t("10278")}
                    disabled={isRetailer}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id={"phone"}
                    label={t("10149")}
                    className={classesText.textField}
                    style={{ width: 500, display: "none" }}
                    value={hub.labPhone || ""}
                    margin="normal"
                    helperText={t("10105")}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled={isRetailer}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/*GRID CONTENENTE IL DEVICE DELL'UTENTE*/}


          <Grid item xs={12}>
            <p align="center">
              <strong>
                {t("10043")}
              </strong>
            </p>
          </Grid>
          <DevicesTable
            allBrandsDevices={allBrandsDevices}
            hubDevices={adminTableRowData.hubDevices}
            hubDevicesCount={UserUtils.createJsonDevices(selectedDeviceJSON, true).length}
            selectedDeviceJSON={selectedDeviceJSON}
            setSelectedDeviceJSON={setSelectedDeviceJSON}
          />

          {/*GRID CONTENENTE LE SCOPES DELL'UTENTE*/}
          {
            allScopes.length > 0 ? (
              <div>
                <Grid item xs={12}>
                  <p align="center">
                    <strong>
                      {t("10058")}
                    </strong>
                  </p>
                </Grid>
                <ScopesTable
                  allScopes={allScopes}
                  hubScopes={adminTableRowData.hubScopes}
                  scopesJSON={hubScopesJSON}
                  hubId={hub.hubId}
                  urlAccessCode={urlAccessCode}
                  setScopesJSON={setHubScopesJSON}
                />
              </div>
            ) : (
              null
            )
          }


          {/*BOTTONI PER SALVATAGGIO*/}
          <AdminTableSaveButton
            {...props}
            handleSave={handleSave}
            disabled={disableSave()}
          />
          <AdminTableOkSaved
            {...props}
            dialogMsg={dialogMsg}
            handleClose={handleClose}
            isSaving={isSaving}
            openDialog={openDialog}
          />
        </CardBody>
      </Card>
    </div>
  )
}

// Griglia interna alla finestra del LaboratoryUser
export default function Hub(props) {
  const { adminTableRowData, creation } = props
  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: theme.spacing(2),
    },
  }))
  const classes = useStyles()
  if (adminTableRowData) {
    return <EdgingGridContainer {...props} />
  } else if (!adminTableRowData && creation) {
    return <EdgingGridContainer {...props} />
  } else {
    return <CircularProgress className={classes.progress} />
  }
}
